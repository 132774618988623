<template>
  <div>
    <page-header :isIndex="0"></page-header>
    <div class="tops">
      <div class="top-content"></div>
    </div>
    <div class="indexs">
      <div class="title">选择维普查重系统版本</div>
      <div class="wrapper">
        <div class="index-content grid col-4">
          <div v-for="(item, index) in cons" :key="index" class="box-item">
            <div
              class="box-content"
              :class="consIndex == index ? 'box-active' : ''"
            >
              <div class="item-image contain"></div>
              <div class="item-icon cover">
                <p>官方检测</p>
              </div>
              <div class="item-title">{{ item.title }}</div>
              <div class="item-price">
                <span>{{ item.price }}</span
                >元/1000字符
              </div>
              <div class="item-des">{{ item.des }}</div>
              <div
                class="item-btn flex justify-center"
                @click="handlePath(index)"
              >
                <div class="btn">立即检查</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import storeage from "store";
export default {
  data() {
    return {
      cons: [
        {
          title: "维普大学生版",
          des:
            "维普查重本科版又叫大学生版，结果与学校一致，多版本检测报告呈现多维度检测指标，如学校用的是维普系统，那么按学位选择查重系统即可，专科和本科选本科版，硕博选硕博",
          price: "4.00",
        },
        {
          title: "维普研究生版",
          des:
            "维普研究生版适用于硕士论文、博士论文、研究生论文、MBA论文查重，对比库含硕博学位论文，数据库覆盖全面，检测结果与学校一致，硕博论文定稿检测使用。",
          price: "4.00",
        },
        {
          title: "维普编辑部版",
          des:
            "维普编辑部版适用于编辑部等部门一般文章的检测，面向投稿期刊发表的用户，符合维普收录所有期刊的投稿审查标准，后期要发表的用户请选择维普职称版检测。",
          price: "4.00",
        },
        {
          title: "维普职称版",
          des:
            "维普职称版检测系统可检测已发表或者即将要发表的论文，面向申报职称的各类专业技术人员、学生、教师、科研人员等，各省职称评审办公室均认可该检测结果。",
          price: "40.00",
        },
      ],
      consIndex: 0,
    };
  },
  methods: {
    handlePath(index) {
      // if (index == 0 || index == 3) {
      //   storeage.set("PortalIndex", index);
      //   this.$router.push({
      //     name: "Portal",
      //   });
      // }
      storeage.set("PortalIndex", index);
        this.$router.push({
          name: "Portal",
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tops {
  position: relative;
  width: 100%;
  height: 480px;
  margin-top: 76px;
  .top-content {
    height: 100%;
    background-image: url("~@/assets/top.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.indexs {
  padding: 30px 0;
  background: #fff;
  .title {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }
}
.index-content {
  position: relative;
  padding: 30px 0 100px;

  .box-item {
    position: relative;
  }
  .box-content {
    margin: 10px;
    height: 440px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    padding: 15px;
    &:hover {
      margin-top: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transition: all 0.5s;
    }
    .item-image {
      height: 85px;
      margin: 24px 0;
      background-image: url("~@/assets/w.png");
    }
    .item-icon {
      position: absolute;
      right: 10px;
      top: -4px;
      text-align: center;
      width: 40px;
      height: 44px;
      z-index: 1;
      background-image: url("~@/assets/icon.png");
      p {
        font-size: 12px;
        padding: 0;
        color: #fff;
        padding-left: 5px;
        margin: 0;
      }
    }
    .item-title {
      height: 40px;
      line-height: 40px;
      color: #4560c3;
      text-align: center;
      font-size: 20px;
    }
    .item-price {
      padding: 15px 0;
      text-align: center;
      span {
        font-size: 24px;
        color: #4560c3;
        font-weight: 600;
      }
    }
    .item-des {
      line-height: 24px;
      height: 150px;
      font-size: 13px;
    }
    .item-btn {
      height: 40px;
      .btn {
        padding: 0 25px;
        color: #fff;
        height: 40px;
        background-color: #4560c3;
        line-height: 40px;
        border-radius: 10px;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
